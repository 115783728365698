import RoomModel from "gde-game-framework/src/models/room";
import React, { useCallback } from "react";
import NavalMap from "../../../components/map";
import { useGameFramework } from "../../../modules/game";
import useShip from "../../../modules/game/hooks/engine/use-ship";

type Props = {
    room: RoomModel,
    shipID: string
}

const HelmRoom: React.FC<Props> = ({ room, shipID }) => {

    const { engine } = useGameFramework();
    const { ship } = useShip(shipID);

    const turnLeft = useCallback(() => {
        if (ship) {
            engine.setShipHelm(ship.helm - 1, ship.id)
        }
    }, [ship, engine]);

    const turnLeftMax = useCallback(() => {
        if (ship) {
            engine.setShipHelm(-10, ship.id)
        }
    }, [ship, engine]);

    const turnRight = useCallback(() => {
        if (ship) {
            engine.setShipHelm(ship.helm + 1, ship.id)
        }
    }, [ship, engine]);

    const turnRightMax = useCallback(() => {
        if (ship) {
            engine.setShipHelm(10, ship.id)
        }
    }, [ship, engine]);

    const resetTurn = useCallback(() => {
        if (ship) {
            engine.setShipHelm(0, ship.id)
        }
    }, [ship, engine]);

    return <div>
        <h2>Stery </h2>
        <p>Statek: {ship?.name}</p>

        <div>
            <h3>Mapa</h3>
            <div style={{
                maxWidth: '100vh'
            }}>
                <NavalMap centerOn={shipID} orientWithCenter={true} />

            </div>
        </div>
        <div>
            <h3>Stery kierunku</h3>

            <p>Aktualne ustawienie steru: {ship?.helm}</p>
            <button onClick={turnLeftMax}>Ster lewo na burt</button>
            <button onClick={turnLeft}>Ster lewo</button>
            <button onClick={resetTurn}>Ster prosto</button>
            <button onClick={turnRight}>Ster prawo</button>
            <button onClick={turnRightMax}>Ster prawo na burt</button>
        </div>
        <div>
            <h3>Stery glebokosci</h3>
            <ul>
                <li>wynurzenie</li>
                <li>Zanurzenie 10m</li>
                <li>Zanurzenie 100m</li>
                <li>Zanurzenie 200m</li>
                <li>Zanurzenie 300m</li>
            </ul>
        </div>
    </div>
};

export default HelmRoom;

import { ScenarioList } from "okret-engine/dist/okret-game/server/scenarios-manager"
import { useMemo } from "react";
import { useGameFramework } from "../..";
import useRXJS from "../use-rxjs";

export default function useAdmin() {

    const { engine } = useGameFramework();

    const scenarioList: ScenarioList | undefined = useRXJS(engine.$scenarioList);


    return {
        scenarioList: scenarioList || []
    }
}

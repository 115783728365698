import { NavalObject } from "okret-engine";
import TaskWidget from "./widget";

export type WidgetConfig = {
    container: any,
    centerOn?: string | null,
    orientWithCenter?: boolean,
    echoMode: boolean,
    showZones: boolean
}

const BASE_WIDTH = 800;
const BASE_HEIGHT = 600;

export default function createWidget(config: WidgetConfig) {

    const app = new TaskWidget(BASE_WIDTH, BASE_HEIGHT, config);

    config.container.appendChild(app.view);
    app.gameResize();

    return {
        destroy: () => {
            app.destroy(true)
        },
        updateStatic(data: {
            shorelines: [number, number][][],
            zones: {
                name: string,
                shape: [number, number][]
            }[]
        }) {
            app.updateStatic(data);
        },
        updateDynamic(data: { navalObjects: NavalObject[] }) {
            app.updateDynamic(data)
        }
    }
}

import React, { useEffect, useRef } from "react";
import { useGameFramework } from "../../modules/game";
import NavalMapComponent, { MAP_API } from "./component";

type Props = {
    centerOn?: string,
    orientWithCenter?: boolean,
    echoMode?: boolean,
    showZones?: boolean
}

const NavalMap: React.FC<Props> = ({ centerOn, orientWithCenter, echoMode, showZones }) => {

    const { engine } = useGameFramework();
    const mapRef = useRef<MAP_API>(null);

    useEffect(() => {
        engine.map.all.subscribe(({ staticData, dynamicData }) => {
            if (mapRef.current) {
                mapRef.current.updateStatic(staticData);
                mapRef.current.updateDynamic(dynamicData);
            }
        });

        engine.getMapData();
    }, [engine])

    return <NavalMapComponent ref={mapRef} centerOn={centerOn} orientWithCenter={orientWithCenter || Boolean(centerOn)} echoMode={echoMode || false} showZones={showZones || false} />
};

export default NavalMap;

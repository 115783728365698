import React, { useMemo } from "react";
import ShipRoomList from "../../components/ship-room-list";
import useActiveRoomId from "../../modules/game/hooks/rooms/use-active-room-id"
import useGameClient from "../../modules/game/hooks/rooms/use-game-client";
import useRoom from "../../modules/game/hooks/rooms/use-room";
import AdminControlls from "./admin-controlls";
import CaptainRoom from "./captain-room";
import DemoObjects from "./demo-objects";
import EchoRoom from "./echo-room";
import EngineRoom from "./engines-room";
import HelmRoom from "./helm-room";
import MainLobby from "./main-lobby";
import ReactorRoom from "./reactor-room";
import TeamLobby from "./team-lobby";
import ToppedoRoom from "./torpedo-room";

export default function GamePage() {

    const { isConnected } = useGameClient();

    return <>
        {isConnected && <GameView />}
    </>;
}

const GameView: React.FC = () => {

    const roomId = useActiveRoomId();
    const { room, shipID, teamID } = useRoom(roomId || '');

    const view = useMemo(() => {

        if (roomId && room) {
            switch (roomId) {
                case 'main-lobby':
                    return <MainLobby />

                case 'demo-objects':
                    return <DemoObjects />

                case 'admin-controlls':
                    return <AdminControlls />
            }

            if (room.tags.includes('team-lobby')) {
                return <TeamLobby roomId={roomId} />
            }

            if (shipID && teamID) {
                if (room.tags.includes('helm')) {
                    return <>
                        <ShipRoomList shipID={shipID} />
                        <HelmRoom room={room} shipID={shipID} />
                    </>
                }

                if (room.tags.includes('echo')) {
                    return <>
                        <ShipRoomList shipID={shipID} />
                        <EchoRoom room={room} shipID={shipID} />
                    </>
                }

                if (room.tags.includes('engines')) {
                    return <>
                        <ShipRoomList shipID={shipID} />
                        <EngineRoom room={room} shipID={shipID} />
                    </>
                }

                if (room.tags.includes('front-torpedo')) {
                    return <>
                        <ShipRoomList shipID={shipID} />
                        <ToppedoRoom room={room} shipID={shipID} roomVersion="front" />
                    </>
                }

                if (room.tags.includes('back-torpedo')) {
                    return <>
                        <ShipRoomList shipID={shipID} />
                        <ToppedoRoom room={room} shipID={shipID} roomVersion="back" />
                    </>
                }

                if (room.tags.includes('captain')) {
                    return <>
                        <ShipRoomList shipID={shipID} />
                        <CaptainRoom room={room} shipID={shipID} />
                    </>
                }

                if (room.tags.includes('reactor')) {
                    return <>
                        <ShipRoomList shipID={shipID} />
                        <ReactorRoom room={room} shipID={shipID} />
                    </>
                }
            }
        }

        return <p style={{ color: 'red' }}>{roomId} {JSON.stringify(room)}</p>;


    }, [roomId, room, shipID, teamID])

    return <div>
        <h1>Jesteś w grze!</h1>
        {JSON.stringify(room)}
        {JSON.stringify({ shipID })}
        {view}
    </div>
}

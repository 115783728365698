import * as PIXI from 'pixi.js';

export default class ZonesLayer extends PIXI.Container {
    gfx = new PIXI.Graphics();

    constructor() {
        super();
        this.addChild(this.gfx);
    }

    update(zones: {
        name: string,
        shape: [number, number][]
    }[]) {
        this.gfx.clear();

        zones.forEach(z => {
            this.gfx.lineStyle(1, 0xaa0000, 1);
            this.gfx.beginFill(0xaa0000, .2);
            this.gfx.drawPolygon(...z.shape.reduce((prev: number[], next) => {
                return [
                    ...prev,
                    ...next
                ];
            }, []));
            this.gfx.endFill();

        })

    }
}

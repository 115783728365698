import React from "react";
import NavalMap from "../../../components/map";
import useTorpedo from "../../../modules/game/hooks/engine/use-topedo";

type Props = {
    id: string
}

const TorpedoControlls: React.FC<Props> = ({ id }) => {

    const {
        torpedo,
        turnLeftMax,
        turnLeft,
        resetTurn,
        turnRight,
        turnRightMax
    } = useTorpedo(id);

    return <div>
        <div style={{
            maxWidth: '100vh'
        }}>
            <NavalMap centerOn={id} orientWithCenter={Boolean(id)} />
        </div>
        <p>Aktualne ustawienie steru: {torpedo?.helm}</p>
        <button onClick={turnLeftMax}>Ster lewo na burt</button>
        <button onClick={turnLeft}>Ster lewo</button>
        <button onClick={resetTurn}>Ster prosto</button>
        <button onClick={turnRight}>Ster prawo</button>
        <button onClick={turnRightMax}>Ster prawo na burt</button>
    </div>
}

export default TorpedoControlls;

import { GDEGameClient } from 'gde-game-framework';
import React, { useContext, useEffect, useRef } from 'react';
import { OkretGameEngine } from 'okret-engine';

export default function createClient(url: string) {
    const engine = new OkretGameEngine.Client();


    const client = new GDEGameClient({
        gameServerUrl: url,
        gameEngine: engine,
    });


    const context = React.createContext<{
        client: GDEGameClient,
        engine: OkretGameEngine.Client
    }>({ client, engine });

    const GameClientProvider: React.FunctionComponent<{ authToken: string }> = ({ children, authToken }) => {

        const gameClient = useRef(client);
        const gameEngine = useRef(engine);

        useEffect(() => {
                client.connect(authToken);

                return () => {
                    client.disconnect();
                }
        }, [authToken])

        return (
            <context.Provider value={{client: gameClient.current, engine: gameEngine.current}}>
                {children}
            </context.Provider>
        )
    }
    const useGameFramework = () => {
        return useContext(context);
    }

    return {
        GameClientProvider,
        useGameFramework
    }
}



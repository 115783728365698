"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const rxjs_1 = require("rxjs");
const scenarios_manager_1 = require("../../scenarios-manager");
const events_1 = require("../events");
const SAT = __importStar(require("sat"));
class EventManager {
    constructor(director, config) {
        this.director = director;
        this.config = config;
        this.$action = new rxjs_1.Subject();
        this.zones = new Map();
        this.isActive = true;
        this.isTimeSensitive = config.triggers.some(t => t.type === scenarios_manager_1.TRIGGER_TYPE.TIME);
        const zoneTriggers = config.triggers.filter(scenarios_manager_1.isShipInZoneTrigger);
        const zoneIDs = zoneTriggers.map(t => t.zone);
        this.isTimeSensitive = this.isTimeSensitive || (zoneTriggers.length > 0);
        zoneIDs.forEach(zId => {
            var _a, _b;
            const zone = (_b = (_a = director.scenario) === null || _a === void 0 ? void 0 : _a.map.zones) === null || _b === void 0 ? void 0 : _b.find(z => z.id === zId);
            if (zone) {
                console.log('add zone', zone);
                const zoneShape = new SAT.Polygon(new SAT.Vector(), zone.shape.map(([x, y]) => {
                    return new SAT.Vector(x, y);
                }));
                console.log('TEST:', SAT.pointInPolygon(new SAT.Vector(200, 200), zoneShape));
                this.zones.set(zId, zoneShape);
            }
        });
    }
    check() {
        if (!this.isActive)
            return;
        if (!this.checkFlags(this.config.flagsRequired || [])) {
            return;
        }
        const triggersValid = this.config.triggers.every(t => {
            if (!this.checkFlags(t.flagsRequired || []))
                return;
            switch (t.type) {
                case scenarios_manager_1.TRIGGER_TYPE.TIME:
                    return this.checkTimeTrigger(t);
                case scenarios_manager_1.TRIGGER_TYPE.COUNTER:
                    return this.checkCounterTrigger(t);
                case scenarios_manager_1.TRIGGER_TYPE.SHIP_IN_ZONE:
                    return this.checkShipInZoneTrigger(t);
                default:
                    return false;
            }
        });
        if (triggersValid) {
            this.activate();
        }
    }
    handleGameEvent(gameEvent) {
        switch (gameEvent.type) {
            case events_1.GAME_EVENT.SHIP_DESTROYED:
                this.handleGameDestroyedEvent(gameEvent.payload);
                break;
        }
    }
    activate() {
        if (this.config.once) {
            this.isActive = false;
        }
        this.config.actions.forEach((a) => {
            console.log('ACTIVATE ACTION:', a);
            this.$action.next(a);
        });
    }
    checkFlags(flags) {
        return flags.every(f => this.director.flags.has(f));
    }
    checkTimeTrigger(t) {
        return t.time < this.director.gameTime;
    }
    checkCounterTrigger(t) {
        const value = this.director.counters.get(t.counter);
        if (typeof value === 'undefined')
            return false;
        return value >= t.value;
    }
    checkShipInZoneTrigger(t) {
        const ships = this.director.game.getShipList().filter(s => {
            return (t.ship.ids || []).includes(s.id) || (t.ship.teams || []).includes(s.team);
        });
        const zone = this.zones.get(t.zone);
        if (zone) {
            return ships.some(s => {
                return SAT.pointInPolygon(new SAT.Vector(s.position[0], s.position[1]), zone);
            });
        }
        return false;
    }
    checkBasicTriggers() {
        if (!this.checkFlags(this.config.flagsRequired || [])) {
            return false;
        }
        return this.config.triggers.reduce((prev, next) => {
            if (!prev)
                return prev;
            if (next.type === scenarios_manager_1.TRIGGER_TYPE.TIME) {
                return this.checkTimeTrigger(next);
            }
            if (next.type === scenarios_manager_1.TRIGGER_TYPE.COUNTER) {
                return this.checkCounterTrigger(next);
            }
            return prev;
        }, true);
    }
    handleGameDestroyedEvent(data) {
        if (this.checkBasicTriggers()) {
            const destroyTrigger = this.config.triggers.find(scenarios_manager_1.isDestroyTrigger);
            if (destroyTrigger) {
                let targetCorrect = true;
                let sourceCorrect = true;
                if (destroyTrigger.target) {
                    if (destroyTrigger.target.ships) {
                        targetCorrect = destroyTrigger.target.ships.includes(data.shipId);
                    }
                    if (destroyTrigger.target.teams) {
                        const targetTeam = this.director.game.getShipTeam(data.shipId);
                        if (targetTeam) {
                            targetCorrect = destroyTrigger.target.teams.includes(targetTeam);
                        }
                    }
                }
                if (destroyTrigger.source && data.sourceShipId) {
                    if (destroyTrigger.source.ships) {
                        sourceCorrect = destroyTrigger.source.ships.includes(data.sourceShipId);
                    }
                    if (destroyTrigger.source.teams) {
                        const sourceTeam = this.director.game.getShipTeam(data.sourceShipId);
                        if (sourceTeam) {
                            sourceCorrect = destroyTrigger.source.teams.includes(sourceTeam);
                        }
                    }
                }
                if (targetCorrect && sourceCorrect) {
                    this.activate();
                }
            }
        }
    }
}
exports.default = EventManager;

import { useMemo } from "react";
import { useGameFramework } from "../..";
import useRXJS from "../use-rxjs";

export default function useNavalObject(id: string) {
    const { engine } = useGameFramework();
    const dynamic = useRXJS(engine.map.dynamic);
    const no = useMemo(() => dynamic?.navalObjects.find(o => o.id === id), [dynamic]);

    return no;
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_TYPE = exports.ACTION_DATA_TYPE = exports.REQUEST_DATA_TYPE = void 0;
var REQUEST_DATA_TYPE;
(function (REQUEST_DATA_TYPE) {
    REQUEST_DATA_TYPE["GET_GAME_STATUS"] = "get-game-status";
    REQUEST_DATA_TYPE["GET_MAP_DATA"] = "get-map-data";
    REQUEST_DATA_TYPE["GET_SHIP_LIST"] = "get-ship-list";
    REQUEST_DATA_TYPE["GET_SCENARIO_LIST"] = "get-scenario-list";
    REQUEST_DATA_TYPE["GET_COMS"] = "get-coms";
})(REQUEST_DATA_TYPE = exports.REQUEST_DATA_TYPE || (exports.REQUEST_DATA_TYPE = {}));
var ACTION_DATA_TYPE;
(function (ACTION_DATA_TYPE) {
    ACTION_DATA_TYPE["START_GAME"] = "start-game";
    ACTION_DATA_TYPE["RESTART_GAME"] = "restart-game";
    ACTION_DATA_TYPE["SET_SHIP_SPEED"] = "set-ship-speed";
    ACTION_DATA_TYPE["SET_SHIP_HELM"] = "set-ship-helm";
    ACTION_DATA_TYPE["SET_TORPEDO_HELM"] = "set-torpedo-helm";
    ACTION_DATA_TYPE["FIRE_TORPEDO"] = "fire-torpedo";
    ACTION_DATA_TYPE["MOVE_TORPEDO"] = "move-torpedo";
    ACTION_DATA_TYPE["LOAD_TORPEDO"] = "load-torpedo";
    ACTION_DATA_TYPE["SET_SHIP_POWER_GOAL"] = "set-ship-power-goal";
    ACTION_DATA_TYPE["SET_SHIP_DIRECTION"] = "set-ship-direction";
})(ACTION_DATA_TYPE = exports.ACTION_DATA_TYPE || (exports.ACTION_DATA_TYPE = {}));
var UPDATE_TYPE;
(function (UPDATE_TYPE) {
    UPDATE_TYPE["GAME_STATUS"] = "game-status";
    UPDATE_TYPE["DYNAMIC_MAP_UPDATE"] = "dynamic-map-update";
    UPDATE_TYPE["STATIC_MAP_UPDATE"] = "static-map-update";
    UPDATE_TYPE["MAP_UPDATE"] = "map-update";
    UPDATE_TYPE["GAME_START"] = "game-start";
    UPDATE_TYPE["SHIP_LIST"] = "ship-list";
    UPDATE_TYPE["COMS_UPDATE"] = "coms-update";
    UPDATE_TYPE["GAME_RESTART"] = "game-restart";
})(UPDATE_TYPE = exports.UPDATE_TYPE || (exports.UPDATE_TYPE = {}));

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Funkcja aktualizująca pozycje obiektów pływających w każdym kroku symulacji
 *
 * @export
 * @param {NavalObject} nObj
 * @param {number} dt
 */
function updateNavalObject(nObj, dt) {
    if (nObj.speedModel === 'power') {
        if (nObj.velosity >= 0) {
            const velLost = Math.pow(nObj.velosity, 2) * nObj.forwardMoveResist * dt;
            nObj.velosity -= velLost;
        }
        if (nObj.velosity < 0) {
            const velLost = Math.pow(nObj.velosity, 2) * nObj.backwardMoveResist * dt;
            nObj.velosity += velLost;
        }
        if (Math.abs(nObj.velosity) < .05) {
            nObj.velosity = 0;
        }
    }
    const move = [
        nObj.velosity * Math.sin(nObj.angle) * dt,
        nObj.velosity * -Math.cos(nObj.angle) * dt,
    ];
    const dDngle = nObj.helm * Math.PI / 180 * dt;
    if (!isNaN(move[0]) && !isNaN(move[1]) && !isNaN(dDngle)) {
        nObj.angle += dDngle;
        if ((nObj.angle) > 2 * Math.PI) {
            nObj.angle -= 2 * Math.PI;
        }
        if ((nObj.angle) < 0) {
            nObj.angle += 2 * Math.PI;
        }
        nObj.position[0] += move[0];
        nObj.position[1] += move[1];
        if (nObj.position[0] < 0 ||
            nObj.position[1] < 0 ||
            nObj.position[0] > 800 ||
            nObj.position[1] > 600) {
            nObj.__deleteMe = true;
        }
    }
    else {
        console.log(nObj);
        process.exit();
    }
}
exports.default = updateNavalObject;

import { useCallback } from "react";
import { useGameFramework } from "../..";
import useShip from "../engine/use-ship";
import { MOVE_DIRECTION } from "okret-engine";

export default function useReactorRoom(shipId: string) {

    const {ship} = useShip(shipId);

    const {engine} = useGameFramework();

    const setReactorPower = useCallback((power: number) => {
        engine.setShipReactorPower(shipId, power)
    }, [shipId, engine]);

    const setMoveDirection = useCallback((direction: MOVE_DIRECTION) => {
        engine.setShipDirection(shipId, direction);
    }, [engine, shipId]);

    return {
        goalPower: ship?.reactorGoalPower,
        power: ship?.reactorActualPower,
        temperature: ship?.reactorTemperature,
        velocity: ship?.velosity,
        moveDirection: ship?.moveDirection,
        setReactorPower,
        setMoveDirection,
    }
}

import { NavalObject } from 'okret-engine';
import { NAVAL_OBJECT } from 'okret-engine/dist/okret-game/naval-map';
import * as PIXI from 'pixi.js';

export default class NavalObjects extends PIXI.Container {
    private gfx = new PIXI.Graphics();

    constructor() {
        super();

        this.addChild(this.gfx);
    }

    update(data: {navalObjects: NavalObject[]}) {
        this.gfx.clear();



        data.navalObjects.forEach((no) => {
            if (no.type === NAVAL_OBJECT.SHIP) {
                this.gfx.lineStyle(2, 0xff0000, 1);
                this.gfx.drawCircle(no.position[0], no.position[1], 10);
                this.gfx.moveTo(no.position[0], no.position[1]);
                this.gfx.lineTo(no.position[0] + 10 * -Math.sin(no.angle), no.position[1] + 10 * Math.cos(no.angle))
            }

            if (no.type === NAVAL_OBJECT.TORPEDO) {
                this.gfx.lineStyle(1, 0xff0000, 1);
                this.gfx.drawCircle(no.position[0], no.position[1], 3);
                this.gfx.moveTo(no.position[0], no.position[1]);
                this.gfx.lineTo(no.position[0] + 3 * -Math.sin(no.angle), no.position[1] + 3 * Math.cos(no.angle))
            }
        });
    }
}

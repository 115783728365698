import { useMemo } from "react";
import { useGameFramework } from "../..";
import useRXJS from "../use-rxjs";

/**
 * Hook do pobierania danych statku o danym ID
 *
 * @param id
 * @returns
 */
export default function useShip(id: string) {
    const { engine } = useGameFramework();

    const shipModel = useMemo(() => {
        return engine.getShipByID(id);
    }, [id, engine])

    const ship = useRXJS(shipModel?.$data);

    return {
        ship: ship || null
    }
}

import { useCallback } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import useLogin from "../../modules/main/hooks/use-login";

type Props = {}

const Container = styled.div`

`;

const LoginForm: React.FC<Props> = () => {

    const { login } = useLogin()

    const { register, handleSubmit } = useForm();

    const onSubmit = useCallback((data: any) => {
        login({ login: data.name });
    }, [login])

    return <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
            <h1>Gracz</h1>
            <input type="text" id="" {...register('name')} />
            <button type="submit">ZALOGUJ</button>
        </form>
        <h1>Admin</h1>
        <button onClick={() => {
            login({ login: '__admin__' })
        }}>Zaloguj jako admin</button>
    </Container>
}

export default LoginForm;

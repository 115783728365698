import RoomModel from "gde-game-framework/src/models/room";
import React from "react";
import useTorpedoRoom from "../../../modules/game/hooks/ship-rooms/use-torpedo-room";
import TorpedoControlls from "./torpedo-controlls";

type Props = {
    room: RoomModel,
    roomVersion: 'back' | 'front',
    shipID: string
}

const ToppedoRoom: React.FC<Props> = ({ shipID, roomVersion }) => {

    const { torpedos, movingTorpedos, loadTorpedo, loadingTorpedo, loadedTorpedo, fireTorpedo, torpedoInWaterId } = useTorpedoRoom(shipID, roomVersion);

    return <div>
        <h2>Torpedownia</h2>

        <h3>Torpedy w podręcznym magazynie</h3>
        <ol>
            {torpedos.map(t => {
                return <li key={t.id}>
                    {t.type}
                    <button onClick={() => { loadTorpedo(t.id) }}>Załaduj</button>
                </li>
            })}
        </ol>
        {loadingTorpedo && <div>
            <h3>Ładujemy torpedę</h3>
            <p>Typ: {loadingTorpedo.torpedo.type}</p>
            <p>CZas do końca: {loadingTorpedo.timeToEnd}</p>
        </div>}
        {loadedTorpedo && <div>
            <h3>Załadowana torpeda</h3>
            <p>Typ: {loadedTorpedo.type} - ID: {loadedTorpedo.id}</p>
            <button onClick={() => {fireTorpedo(loadedTorpedo.id)}}>OGNIA!</button>
        </div>
        }
        {torpedoInWaterId && <div>
            <h3>Torpeda w wodzie!</h3>
            <p>ID:{torpedoInWaterId}</p>
            <TorpedoControlls id={torpedoInWaterId} />
        </div>
        }
        <h3>Torpedy w transporcie</h3>
        <ol>
            {movingTorpedos.map(t => {
                return <li key={t.torpedo.id}>
                    {t.torpedo.type} | Czas transportu: {t.timeToEnd}
                </li>
            })}
        </ol>
    </div>
};

export default ToppedoRoom;

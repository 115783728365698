import { useCallback } from "react";
import { useGameFramework } from "../..";
import useNavalObject from "./use-naval-object"

/**
 * Hook do pobrania danych torpedy
 *
 * @param id
 * @returns
 */
export default function useTorpedo(id: string) {

    const torpedo = useNavalObject(id);
    const { engine } = useGameFramework();

    const turnLeft = useCallback(() => {
        if (torpedo) {
            engine.setTorpedoHelm(torpedo.helm - 1, torpedo.id)
        }
    }, [torpedo, engine]);

    const turnLeftMax = useCallback(() => {
        if (torpedo) {
            engine.setTorpedoHelm(-10, torpedo.id)
        }
    }, [torpedo, engine]);

    const turnRight = useCallback(() => {
        if (torpedo) {
            engine.setTorpedoHelm(torpedo.helm + 1, torpedo.id)
        }
    }, [torpedo, engine]);

    const turnRightMax = useCallback(() => {
        if (torpedo) {
            engine.setTorpedoHelm(10, torpedo.id)
        }
    }, [torpedo, engine]);

    const resetTurn = useCallback(() => {
        if (torpedo) {
            engine.setTorpedoHelm(0, torpedo.id)
        }
    }, [torpedo, engine]);

    return {
        torpedo,
        turnLeftMax,
        turnLeft,
        resetTurn,
        turnRight,
        turnRightMax
    }
}

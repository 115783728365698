"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const naval_map_1 = require("../../../naval-map");
const torpedo_on_ship_1 = require("../../../naval-map/models/torpedo-on-ship");
function SingleScenario() {
    return {
        id: 'single',
        teams: [
            { id: 'red', enemyTeams: ['yellow'], friendlyTeams: [] }
        ],
        map: {
            width: 800,
            height: 600,
            shorelines: [
                [[10, 10], [790, 10], [790, 590], [10, 590], [10, 10]]
            ],
            ships: [{
                    id: 'red-1',
                    type: naval_map_1.NAVAL_OBJECT.SHIP,
                    position: [400, 300],
                    course: 0,
                    velosity: 0,
                    speedModel: naval_map_1.SPEED_MODEL.POWER,
                    team: 'red',
                    name: 'Red 1',
                    helm: 0,
                    torpedoStorage: [{
                            type: torpedo_on_ship_1.TORPEDO_TYPE.MK1,
                            count: 30
                        }]
                }]
        },
        counters: [],
        events: [],
        flags: [],
        messages: [],
    };
}
exports.default = SingleScenario;

import RoomModel from "gde-game-framework/src/models/room";
import NavalMap from "../../../components/map";
import useShip from "../../../modules/game/hooks/engine/use-ship";

type Props = {
    room: RoomModel,
    shipID: string
}

const EchoRoom: React.FC<Props> = ({ room, shipID }) => {

    const { ship } = useShip(shipID);

    return <div>
        <h2>Echo</h2>
        <p>Statek: {ship?.name}</p>

        <div>
            <h3>Mapa</h3>
            <div style={{
                maxWidth: '100vh'
            }}>
                <NavalMap centerOn={shipID} orientWithCenter={true} echoMode={true} />
            </div>
        </div>
        <div>
            <h3>Przełacznik trybu mapy</h3>
            <ul>
                <li>sonar</li>
                <li>miny</li>
            </ul>
        </div>
        <div>
            <h3>Alarm torpedowy</h3>
        </div>
        <div>
            <h3>Wabie</h3>
            <li>Wystrzel do przodu</li>
            <li>Wystrzel do tyłu</li>
        </div>
    </div>
};

export default EchoRoom;

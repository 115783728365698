import { NavalObject } from "okret-engine";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useEffect, useRef } from "react";
import createWidget from "./create-widget";

type Props = {
    centerOn?: string
    orientWithCenter?: boolean,
    echoMode: boolean,
    showZones: boolean
}

export type MAP_API = {
    updateStatic(data: { shorelines: [number, number][][] }): void;
    updateDynamic(data: { navalObjects: NavalObject[] }): void;
}

const NavalMapComponent = forwardRef<MAP_API, Props>(
    ({ centerOn, orientWithCenter, echoMode, showZones }, ref) => {
        const widgetContainer = useRef<HTMLDivElement>(null);
        const [gameAPI, setGameAPI] = useState<MAP_API>()

        useImperativeHandle(ref, () => ({
            updateStatic: (data: { shorelines: [number, number][][] }) => {
                if (gameAPI) {
                    return gameAPI.updateStatic(data);
                }
            },
            updateDynamic: (data: { navalObjects: NavalObject[] }) => {
                if (gameAPI) {
                    return gameAPI.updateDynamic(data);
                }
            },

        }));

        useEffect(() => {
            console.log('createWidget')

            const gameConfig = {
                container: widgetContainer.current,
                centerOn: centerOn || null,
                orientWithCenter: orientWithCenter || false,
                echoMode,
                showZones
            }

            const {destroy, updateStatic, updateDynamic} = createWidget(gameConfig)

            setGameAPI({
                updateStatic,
                updateDynamic
            })

            return () => {
                console.log('DESTRO!!!')
                destroy();
            }

        }, [setGameAPI, centerOn, orientWithCenter, echoMode])

        return <div className="naval-map">
            <div ref={widgetContainer} className="gameContainer" style={{ width: '100%', margin: '0 auto', userSelect: 'none' }}></div>
        </div>
    }
);

export default NavalMapComponent

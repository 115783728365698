export function getHomeRoute() {
    return '/'
}

export function getLoginRoute() {
    return '/login'
}

export function getGameRoute() {
    return '/gra'
}

import useRXJS from "../use-rxjs";
import useGameClient from "./use-game-client";

export default function useRoomsList() {
    const {client, isConnected} = useGameClient();

    const rooms = useRXJS(client.getRooms());

    return {
        isConnected,
        rooms
    }
}

import { CLIENT_CONNECTION } from "gde-game-framework";
import { useGameFramework } from "../..";
import useRXJS from "../use-rxjs";

export default function useGameClient() {
    const {client} = useGameFramework();
    const connectionState = useRXJS(client.$connectionState);

    return {
        client,
        connectionState,
        isConnected: connectionState === CLIENT_CONNECTION.CONNECTED
    }
}

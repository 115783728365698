"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function getGameConfig() {
    return {
        // Rozmiar statku na mapie
        SHIP_SIZE: 10,
        // Rozmiar torpedy na mapie
        TORPEDO_SIZE: 1,
        // Czas potrzebny na transport torpedy
        TORPEDO_TRANSPORT_TIME: 3,
        // Czas potrzebny na załadowanie torpedy
        TORPEDO_LOADING_TIME: 3,
        SHIP_REACTOR_MAX_POWER: 25,
        SHIP_REACTOR_GREEN_LIMIT: 10,
        SHIP_REACTOR_YELOW_LIMIT: 15,
        SHIP_REACTOR_RED_LIMIT: 20,
        SHIP_REACTOR_BASE_TEMP: 30,
        SHIP_REACTOR_SHOUTDOWN_TEMPERATURE: 100,
    };
}
exports.default = getGameConfig;

import { useEffect } from "react";
import useComs from "../../modules/game/hooks/engine/use-coms"

type Props = {
    shipID: string
}

const ScenarioComs: React.FC<Props> = ({shipID}) => {

    const {messages, requestUpdate} = useComs(shipID);

    useEffect(() => {
        requestUpdate()
    }, [requestUpdate])

    return <>
        <h2>Wiadomości</h2>
        <ol>
            {messages?.map(msg => {
                return <div key={msg.id} style={{background: '#d7ee23', padding: 10, marginBottom: 10}}>
                    <h3>{msg.title}</h3>
                    <h4>OD: {msg.from}</h4>
                    <div>{msg.text}</div>

                </div>
            })}
        </ol>
    </>
}

export default ScenarioComs;

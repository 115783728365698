import * as PIXI from 'pixi.js';

export default class Shoreline extends PIXI.Container {
    private gfx = new PIXI.Graphics();

    constructor() {
        super()

        this.addChild(this.gfx);
    }

    update(data: [number, number][][]) {
        this.gfx.clear();

        this.gfx.lineStyle(4, 0xffd900, 1);

        data.forEach(line => {
            this.gfx.moveTo(line[0][0], line[0][1]);

            line.forEach(([x,y]) => {
                this.gfx.lineTo(x,y);
            });
        });
    }
}

import useUserInRoom from "../../modules/game/hooks/rooms/use-user-in-room";

type Props = {
    id: string
}

const UsersInRoom: React.FC<Props> = ({id}) => {

    const {users} = useUserInRoom(id);

    return <div>
        <ol>
            {users.map(u => {
                return <li key={u.id}>{u.data?.name}</li>
            })}
        </ol>
    </div>
}

export default UsersInRoom;

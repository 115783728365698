"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
     * Usuwa z GameFrameworkConfig dane niejawne dla usera.
     *
     * @private
     * @param {GameFrameworkConfig} input
     * @param {BaseUserModel} [user]
     * @returns {GameFrameworkConfig}
     * @memberof GDEGameServer
     */
function filterFrameworkConfig(input, user) {
    const permisions = (user === null || user === void 0 ? void 0 : user.permisions) || [];
    console.log({ permisions });
    return Object.assign(Object.assign({}, input), { rooms: input.rooms.reduce((result, room) => {
            // User wie o pokojach które nie mają wymagań
            if (room.require.length === 0)
                return [
                    ...result,
                    room
                ];
            // User wie o pokojach których wszystkie wymagania spełnił
            if (room.require.every(req => permisions.includes(req)))
                return [
                    ...result,
                    room
                ];
            return result;
        }, []) });
}
exports.default = filterFrameworkConfig;

import TorpedoOnShipModel from "okret-engine/dist/okret-game/naval-map/models/torpedo-on-ship";
import { useCallback, useMemo } from "react";
import { useGameFramework } from "../..";
import useShip from "../engine/use-ship";

/**
 * Hook z danymi i metodami używanym w torpedowni
 *
 * @export
 * @param {string} shipId
 * @param {('back' | 'front')} roomVersion
 * @returns
 */
export default function useTorpedoRoom(shipId: string, roomVersion: 'back' | 'front') {

    const {engine} = useGameFramework();

    const {ship} = useShip(shipId);

    const torpedos: TorpedoOnShipModel[] = useMemo(() => {
        if(ship) {
            return roomVersion === 'front' ? ship.frontTorpedoStorage : ship.backTorpedoStorage;
        }

        return []
    }, [ship, roomVersion])

    const movingTorpedos: {torpedo: TorpedoOnShipModel, goal: 'back' | 'front', timeToEnd: number}[] = useMemo(() => {
        if(ship) {
            return ship.torpedosInMove.filter(t => t.goal === roomVersion);
        }

        return []
    }, [ship, roomVersion]);

    const loadingTorpedo: {torpedo: TorpedoOnShipModel, timeToEnd: number} | null = useMemo(() => {
        if (ship) {
            if (roomVersion === 'back') {
                return ship.backTorpedoLoading;
            }

            if (roomVersion === 'front') {
                return ship.frontTorpedoLoading;
            }
        }

        return null
    }, [ship, roomVersion])

    const loadedTorpedo: TorpedoOnShipModel | null = useMemo(() => {
        if (ship) {
            if (roomVersion === 'back') {
                return ship.backTorpedoLoaded;
            }

            if (roomVersion === 'front') {
                return ship.frontTorpedoLoaded;
            }
        }

        return null;
    }, [ship, roomVersion])

    const torpedoInWaterId = useMemo(() => {
        if (ship) {
            if (roomVersion === 'back') {
                return ship.backTorpedoInWaterId;
            }

            if (roomVersion === 'front') {
                return ship.frontTorpedoInWaterId;
            }
        }

        return null;
    }, [ship, roomVersion])

    const loadTorpedo = useCallback((torpedoId: string) => {
        engine.loadTorpedo(shipId, roomVersion, torpedoId);
    }, [shipId, engine, roomVersion])

    const fireTorpedo = useCallback((torpedoId: string) => {
        engine.fireTorpedo(shipId, roomVersion, torpedoId)
    }, [shipId, roomVersion])

    return {
        torpedos,
        movingTorpedos,
        loadingTorpedo,
        loadedTorpedo,
        torpedoInWaterId,
        loadTorpedo,
        fireTorpedo
    }
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const rxjs_1 = require("rxjs");
class ShipComs {
    constructor() {
        this.$scenarioMessages = new rxjs_1.BehaviorSubject([]);
    }
    destroy() {
        this.$scenarioMessages.complete();
    }
    receiveScenarioMessage(message) {
        this.$scenarioMessages.next([...this.$scenarioMessages.value, message]);
    }
    resetScenarioMessages(messages) {
        this.$scenarioMessages.next(messages);
    }
}
exports.default = ShipComs;

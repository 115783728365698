import useRXJS from "../use-rxjs";
import useGameClient from "./use-game-client";

export default function useUserInRoom(id: string) {
    const {client} = useGameClient();
    const users = useRXJS(client.getRoomUsers(id));

    return {
        users: users || []
    }
}

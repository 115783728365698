"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const naval_map_1 = require("../../../naval-map");
const torpedo_on_ship_1 = require("../../../naval-map/models/torpedo-on-ship");
function Dev1Scenario() {
    return {
        id: 'dev-1',
        teams: [
            { id: 'red', enemyTeams: ['blue'], friendlyTeams: [] },
            { id: 'blue', enemyTeams: ['red'], friendlyTeams: [] },
            { id: 'black', enemyTeams: ['red'], friendlyTeams: [] }
        ],
        map: {
            width: 800,
            height: 600,
            shorelines: [
                [[10, 10], [790, 10], [790, 590], [10, 590], [10, 10]],
                [[100, 100], [200, 150], [300, 100], [100, 100]]
            ],
            ships: [
                {
                    id: 'blue-1',
                    type: naval_map_1.NAVAL_OBJECT.SHIP,
                    position: [300, 500],
                    course: 270,
                    velosity: 0,
                    power: 10,
                    speedModel: naval_map_1.SPEED_MODEL.POWER,
                    team: 'blue',
                    name: 'Blue 1',
                    helm: 10,
                    torpedoStorage: [{
                            type: torpedo_on_ship_1.TORPEDO_TYPE.MK1,
                            count: 30
                        }]
                },
                {
                    id: 'red-1',
                    type: naval_map_1.NAVAL_OBJECT.SHIP,
                    position: [600, 400],
                    course: 90,
                    velosity: 5,
                    speedModel: naval_map_1.SPEED_MODEL.POWER,
                    team: 'red',
                    name: 'Red 1',
                    helm: -10,
                    torpedoStorage: [{
                            type: torpedo_on_ship_1.TORPEDO_TYPE.MK1,
                            count: 20
                        }]
                },
                {
                    id: 'black-1',
                    type: naval_map_1.NAVAL_OBJECT.SHIP,
                    position: [50, 300],
                    course: 90,
                    velosity: 0,
                    speedModel: naval_map_1.SPEED_MODEL.POWER,
                    team: 'black',
                    name: 'Black 1',
                    helm: 0,
                    torpedoStorage: [{
                            type: torpedo_on_ship_1.TORPEDO_TYPE.MK1,
                            count: 20
                        }]
                },
                {
                    id: 'yellow-1',
                    type: naval_map_1.NAVAL_OBJECT.SHIP,
                    position: [300, 200],
                    course: 90,
                    velosity: 5,
                    speedModel: naval_map_1.SPEED_MODEL.VELOSITY,
                    team: 'yellow',
                    name: 'Yellow 1',
                    helm: 0
                },
                {
                    id: 'yellow-2',
                    type: naval_map_1.NAVAL_OBJECT.SHIP,
                    position: [350, 200],
                    course: 270,
                    velosity: 5,
                    speedModel: naval_map_1.SPEED_MODEL.VELOSITY,
                    team: 'yellow',
                    name: 'Yellow 2',
                    helm: 0
                },
                {
                    id: 'yellow-3',
                    type: naval_map_1.NAVAL_OBJECT.SHIP,
                    position: [400, 200],
                    course: 270,
                    velosity: 5,
                    speedModel: naval_map_1.SPEED_MODEL.VELOSITY,
                    team: 'yellow',
                    name: 'Yellow 3',
                    helm: 0
                },
                {
                    id: 'yellow-4',
                    type: naval_map_1.NAVAL_OBJECT.SHIP,
                    position: [450, 200],
                    course: 90,
                    velosity: 5,
                    speedModel: naval_map_1.SPEED_MODEL.VELOSITY,
                    team: 'yellow',
                    name: 'Yellow 4',
                    helm: 0
                },
                {
                    id: 'yellow-5',
                    type: naval_map_1.NAVAL_OBJECT.SHIP,
                    position: [300, 300],
                    course: 90,
                    velosity: 0,
                    speedModel: naval_map_1.SPEED_MODEL.VELOSITY,
                    team: 'yellow',
                    name: 'Yellow 5',
                    helm: 0
                }
            ]
        },
        counters: [],
        events: [],
        messages: [],
        flags: []
    };
}
exports.default = Dev1Scenario;

import React, { useEffect } from "react";
import { useCallback } from "react";
import { useRef } from "react";
import styled from "styled-components";
import NavalMap from "../../../components/map";
import NavalMapComponent from "../../../components/naval-map-component";
import { useGameFramework } from "../../../modules/game";
import useAdmin from "../../../modules/game/hooks/admin/use-admin";
import useRXJS from "../../../modules/game/hooks/use-rxjs";

type Props = {}

const Container = styled.div``;

const AdminControlls: React.FC<Props> = () => {

    const { engine } = useGameFramework();
    const scenarioSelect = useRef<HTMLSelectElement>(null);

    const {scenarioList} = useAdmin();

    useEffect(() => {
        engine.getGameStatus();
    }, [engine])

    // useEffect(() => {
    //     if (scenarioList[0]) {
    //         engine.startGame(scenarioList[0].id);
    //     }
    // }, [engine, scenarioList]);

    const status = useRXJS(engine.$gameState);

    const startGame = useCallback(() => {
        if (scenarioSelect.current && scenarioSelect.current.value) {
            engine.startGame(scenarioSelect.current.value);
        }
    }, [engine]);

    return <Container>
        {JSON.stringify(status)}
        {JSON.stringify(scenarioList)}

        <div>
            <h2>Scenariusz</h2>
            <div>
                Wybierz scenariusz:
                <select ref={scenarioSelect}>
                    {
                        scenarioList.map(s => {
                            return <option key={s.id} value={s.id}>{s.name}</option>
                        })
                    }
                </select>
            </div>
        </div>

        <button onClick={startGame}>Start Game</button>
        <button onClick={() => { engine.restartGame() }}>Restart Game</button>
        <div style={{
            maxWidth: '100vh'
        }}>
            <NavalMap />
        </div>
    </Container>
}

export default AdminControlls;

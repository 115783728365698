import { useMemo } from "react";
import styled from "styled-components";
import { useRooms } from "../../modules/game/hooks/rooms/use-rooms";
import useRoomsList from "../../modules/game/hooks/rooms/use-rooms-list";

type Props = {
    shipID: string
}

const Container = styled.div`
    ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
    }
`;

const ShipRoomList: React.FC<Props> = ({ shipID }) => {

    const { rooms } = useRoomsList();
    const { enter } = useRooms();

    const shipRooms = useMemo(() => {
        if (!rooms) return [];

        return rooms.filter(r => r.tags.includes(`ship-id:${shipID}`));
    }, [shipID, rooms])

    return <Container>
        <ul>
            {shipRooms.map(room => {
                return <li key={room.id}><button onClick={() => enter(room.id)}>{room.name}</button></li>
            })}
        </ul>
    </Container>
};

export default ShipRoomList;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ts_events_1 = require("ts-events");
class Autopilot {
    constructor(ship, path) {
        this.ship = ship;
        this.path = path;
        this.nextPointIndex = 0;
        this.lastDistToTarget = Infinity;
        this.onChangeHelm = new ts_events_1.AsyncEvent();
        this.isActive = true;
        this.id = ship.id;
        this.nextPoint = path[this.nextPointIndex];
    }
    update() {
        if (!this.isActive)
            return;
        const distToTarget = Math.sqrt(Math.pow(this.ship.position[0] - this.nextPoint[0], 2) + Math.pow(this.ship.position[1] - this.nextPoint[1], 2));
        if (distToTarget < 100 && distToTarget > this.lastDistToTarget) {
            this.nextPointIndex++;
            const point = this.path[this.nextPointIndex];
            // console.log('-----------------------------------NEW POINT', point);
            if (point) {
                this.nextPoint = [...point];
                this.lastDistToTarget = Math.sqrt(Math.pow(this.ship.position[0] - this.nextPoint[0], 2) + Math.pow(this.ship.position[1] - this.nextPoint[1], 2));
            }
            else {
                this.isActive = false;
            }
        }
        else {
            this.lastDistToTarget = distToTarget;
        }
        const v = [this.nextPoint[0] - this.ship.position[0], this.nextPoint[1] - this.ship.position[1]];
        let targetAngle = Math.atan2(v[0], -v[1]);
        if (targetAngle < 0) {
            targetAngle += Math.PI * 2;
        }
        let newHelm = 0;
        let dAngle = targetAngle - this.ship.angle;
        if (dAngle > Math.PI) {
            dAngle -= Math.PI;
        }
        this.__DEBUG = {
            v,
            angle: this.ship.angle * 180 / Math.PI,
            targetAngle: targetAngle * 180 / Math.PI,
            dAngle: dAngle * 180 / Math.PI,
            distToTarget,
            nextPoint: this.nextPoint
        };
        if (Math.abs(dAngle) > .01) {
            if (dAngle > 0) {
                newHelm = 10;
            }
            else {
                newHelm = -10;
            }
        }
        else {
            newHelm = 0;
        }
        if (this.ship.helm !== newHelm) {
            this.onChangeHelm.post(newHelm);
        }
    }
}
exports.default = Autopilot;

import React from 'react';
import { Link } from 'react-router-dom';
import useIsLogged from '../../modules/main/hooks/use-is-logged';
import { getGameRoute } from '../routes';


export default function HomePage() {

    const {isLogged} = useIsLogged();

    return <div>
        <h1>Home</h1>
        <p>Zalogowany: {isLogged ? 'TAK' : 'NIE'}</p>
        <Link to={getGameRoute()}>Wejdź do gry</Link>
    </div>
}

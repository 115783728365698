import * as PIXI from 'pixi.js';

export default class MapLines extends PIXI.Container {
    private gfx = new PIXI.Graphics();

    constructor() {
        super()

        this.addChild(this.gfx);

        this.gfx.clear();

        this.gfx.lineStyle(1, 0xd9d9d9, 1);

        for(let i = 0; i < 20; i++) {
            this.gfx.moveTo(-100, 50*i);
            this.gfx.lineTo(1000, 50*i);
            this.gfx.moveTo(50*i, -100);
            this.gfx.lineTo(50*i, 1000);
        }

    }
}

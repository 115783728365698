import { useRooms } from "../../../modules/game/hooks/rooms/use-rooms";
import useRoomsList from "../../../modules/game/hooks/rooms/use-rooms-list";

type Props = {}

const MainLobby: React.FC<Props> = () => {

    const { rooms, isConnected } = useRoomsList();
    const { enter } = useRooms();


    return <>
        {!isConnected && <p style={{ color: 'red' }}>Czekam na połączenie</p>}
        {isConnected && <>
            <h2>Głowny pokój</h2>

            <h3>Wybierz pokój zespołu:</h3>



            {rooms?.filter(r => r.tags.includes('team-lobby'))
                .map(room => {
                    return <ul key={room.id}>
                        <li><button onClick={() => enter(room.id)}>{room.name}</button></li>
                    </ul>
                })}
        </>}
    </>
}

export default MainLobby;

import TorpedoOnShipModel from "okret-engine/dist/okret-game/naval-map/models/torpedo-on-ship";
import { useCallback, useMemo } from "react";
import { useGameFramework } from "../..";
import useShip from "../engine/use-ship";
import useGameClient from "../rooms/use-game-client";

/**
 * Hood z danymi i medodami używanymi w magazynie torped
 *
 * @export
 * @param {string} shipId
 * @returns
 */
export default function useTorpedoStorage(shipId: string) {

    const {engine} = useGameFramework();

    const {ship} = useShip(shipId);

    const torpedos: TorpedoOnShipModel[] = useMemo(() => {
        if(ship) {
            return ship.mainTorpedoStorage;
        }

        return []
    }, [ship])

    const movingTorpedos: {torpedo: TorpedoOnShipModel, goal: 'back' | 'front', timeToEnd: number}[] = useMemo(() => {
        if(ship) {
            return ship.torpedosInMove;
        }

        return []
    }, [ship])

    const moveTorpedo = useCallback((id: string, goal: 'back' | 'front') => {
        engine.moveTorpedo(shipId, id, goal);
    }, [engine, shipId]);

    return {
        torpedos,
        movingTorpedos,
        moveTorpedo
    }
}

import { useMemo } from "react";
import useRoomsList from "./use-rooms-list";

const SHIP_ID_RE = /^ship-id:(.+)$/;
const TEAM_ID_RE = /^team-id:(.+)$/;

export default function useRoom(id: string) {

    const { rooms } = useRoomsList();

    const room = useMemo(() => {
        if (!rooms) return null;

        return rooms.find(r => r.id === id);
    }, [rooms, id])

    const shipID = useMemo(() => {
        if (!room) return '';

        return room.tags.reduce((prev, tag) => {
            if (prev) return prev;

            const exec = SHIP_ID_RE.exec(tag);

            if (exec) {
                return exec[1];
            } else {
                return '';
            }

        }, '')
    }, [room])

    const teamID = useMemo(() => {
        if (!room) return '';

        return room.tags.reduce((prev, tag) => {
            if (prev) return prev;

            const exec = TEAM_ID_RE.exec(tag);

            if (exec) {
                return exec[1];
            } else {
                return '';
            }

        }, '')
    }, [room])

    return {
        room: room || null,
        shipID: shipID || null,
        teamID: teamID || null
    }
}

import { useCallback, useMemo } from "react";
import { useGameFramework } from "../..";
import useRXJS from "../use-rxjs";

export default function useComs(id: string) {
    const { engine } = useGameFramework();

    const shipModel = useMemo(() => {
        return engine.getShipByID(id);
    }, [id, engine])

    const messages = useRXJS(shipModel?.coms.$scenarioMessages);

    const requestUpdate = useCallback(() => {
        engine.updateComs(id);
    }, [id, engine])

    return {
        messages,
        requestUpdate
    }
}

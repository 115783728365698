import RoomModel from "gde-game-framework/src/models/room";
import useReactorRoom from "../../../modules/game/hooks/ship-rooms/use-reactor-room";
import { useForm } from 'react-hook-form';
import { useEffect } from "react";
import { MOVE_DIRECTION } from "okret-engine";

type Props = {
    room: RoomModel,
    shipID: string
}

const ReactorRoom: React.FC<Props> = ({ shipID }) => {

    const { power, goalPower, setReactorPower, velocity, temperature, setMoveDirection, moveDirection } = useReactorRoom(shipID);

    const { register, setValue, watch } = useForm();

    useEffect(() => {
        setValue('goalPower', goalPower);
    }, [goalPower, setValue]);

    useEffect(() => {
        setValue('direction', moveDirection);
    }, [moveDirection, setValue]);

    const _goalPower = watch('goalPower');
    const _direction = watch('direction');

    useEffect(() => {
        const value = parseInt(_goalPower);
        if (!isNaN(value)) {
            setReactorPower(value);
        }
    }, [_goalPower, setReactorPower]);

    useEffect(() => {
        if (_direction) {
            setMoveDirection(_direction);
        }
    }, [_direction, setMoveDirection]);



    return <div>
        <h2>Reactor</h2>

        <p><strong>Aktualna prędkość: {velocity}</strong></p>

        <p>Aktualna moc: {power}</p>
        <p>Moc docelowa: {goalPower}</p>

        <h3>Kierunek</h3>
        <div>
            <label>
                <input type="radio" {...register('direction')} value={MOVE_DIRECTION.FORWARD} />
                Naprzód!
            </label>
        </div>
        <div>
            <label>
                <input type="radio" {...register('direction')} value={MOVE_DIRECTION.BACKWARD} />
                Wstecz!
            </label>
        </div>

        <h3>Kontrola mocy reaktora:</h3>
        <input type="range" min={0} max={25} step={1} defaultValue={goalPower} {...register('goalPower')} />

        <h3>Temperatura reactora</h3>
        <p>Wartość: {temperature}</p>
        {/* <input type="range" min={30} max={100} step={.5} value={temperature} disabled={true}></input> */}

        <button onClick={() => setReactorPower(0)}>SILNIK STOP!</button>

    </div>
};

export default ReactorRoom;

import { useEffect, useState } from "react";
import { Observable } from "rxjs";

export default function useRXJS<T>($obs?: Observable<T>) {
    const [value, setValue] = useState<T>();

    //@ts-ignore
    window.$obs = $obs;

    useEffect(() => {
        if ($obs) {
            const subscriber = $obs.subscribe((v) => {
                setValue(v);
            });

            return () => {
                subscriber.unsubscribe();
            }
        }
    }, [setValue, $obs])

    return value;
}

import { useCallback } from "react";
import useGameClient from "./use-game-client";

export function useRooms() {
    const {client, isConnected} = useGameClient();

    const enter = useCallback((roomId: string) => {
        client.enterRoom(roomId);
    }, [client])

    return {
        enter, isConnected
    }
}

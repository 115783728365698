"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TORPEDO_TYPE = void 0;
var TORPEDO_TYPE;
(function (TORPEDO_TYPE) {
    TORPEDO_TYPE["MK1"] = "mk-1";
})(TORPEDO_TYPE = exports.TORPEDO_TYPE || (exports.TORPEDO_TYPE = {}));
class TorpedoOnShipModel {
    constructor(data) {
        this.type = TORPEDO_TYPE.MK1;
        this.type = data.type,
            this.id = data.id;
    }
}
exports.default = TorpedoOnShipModel;

import RoomModel from "gde-game-framework/src/models/room";
import useTorpedoStorage from "../../../modules/game/hooks/ship-rooms/use-torpedos-storage";

type Props = {
    room: RoomModel,
    shipID: string
}

const EngineRoom: React.FC<Props> = ({ shipID }) => {

    const { torpedos, moveTorpedo, movingTorpedos } = useTorpedoStorage(shipID);

    return <div>
        <h2>Maszynownia</h2>

        <div>
            <h3>Magazyn torped</h3>
            <ol>
                {torpedos.map(t => {
                    return <li key={t.id}>
                        {t.type}
                        <button onClick={() => moveTorpedo(t.id, 'front')}>Wyślij do torpewni dziobowej</button>
                        <button onClick={() => moveTorpedo(t.id, 'back')}>Wyślij do torpewni rufowej</button>
                    </li>
                })}
            </ol>
        </div>
        <div>
            <h3>Torpedy w transporcie</h3>
            <ol>
                {movingTorpedos.map(item => {
                    return <li key={item.torpedo.id}>
                        {item.torpedo.type} | Transportowana do: {item.goal} | Pozostały czas: {item.timeToEnd}
                    </li>
                })}
            </ol>
        </div>

    </div>
};

export default EngineRoom;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const __1 = require("..");
const naval_map_1 = require("../../../naval-map");
const torpedo_on_ship_1 = require("../../../naval-map/models/torpedo-on-ship");
function HuntingScenario() {
    return {
        id: 'hunting',
        teams: [
            { id: 'red', enemyTeams: ['yellow'], friendlyTeams: [] }
        ],
        map: {
            width: 800,
            height: 600,
            shorelines: [
                [[10, 10], [790, 10], [790, 590], [10, 590], [10, 10]]
            ],
            ships: [{
                    id: 'red-1',
                    type: naval_map_1.NAVAL_OBJECT.SHIP,
                    position: [400, 300],
                    course: 0,
                    velosity: 0,
                    speedModel: naval_map_1.SPEED_MODEL.POWER,
                    team: 'red',
                    name: 'Red 1',
                    helm: 0,
                    torpedoStorage: [{
                            type: torpedo_on_ship_1.TORPEDO_TYPE.MK1,
                            count: 30
                        }]
                }],
            zones: [
                {
                    id: 'red-exit',
                    name: 'Exit Zone',
                    shape: [
                        [50, 200],
                        [50, 400],
                        [200, 400],
                        [200, 200]
                    ]
                },
                {
                    id: 'test-zone',
                    name: 'Test Zone',
                    shape: [
                        [0, 0],
                        [0, 250],
                        [800, 250],
                        [800, 0],
                    ]
                }
            ]
        },
        counters: [{
                id: 'phase-1-kills',
            }],
        events: [{
                id: 0,
                description: 'Na starcie gry wysyłamy wiadomość',
                flagsRequired: [],
                triggers: [{
                        flagsRequired: [],
                        type: __1.TRIGGER_TYPE.TIME,
                        time: 20
                    }],
                actions: [{
                        type: __1.ACTION_TYPE.SEND_MESSAGE,
                        messageId: 'game-start'
                    },
                    // {
                    //     type: ACTION_TYPE.TRIGGER_ZONE,
                    //     zone: 'test-zone',
                    //     value: true,
                    //     teams: ['red']
                    // }
                ],
                once: true
            }, {
                id: 100,
                description: 'Zliczamy zniszczenia yellow przez red',
                flagsRequired: ['phase-1'],
                triggers: [{
                        type: __1.TRIGGER_TYPE.DESTROYED,
                        target: { teams: ['yellow'] },
                        source: { teams: ['red'] }
                    }],
                actions: [{
                        type: __1.ACTION_TYPE.INC_COUNTER,
                        counter: 'phase-1-kills'
                    }]
            },
            {
                id: 199,
                description: 'Jak gracze zniszcza 1 wrogi statek to przechodziny do fazy 2.',
                flagsRequired: ['phase-1'],
                triggers: [{
                        type: __1.TRIGGER_TYPE.COUNTER,
                        counter: 'phase-1-kills',
                        value: 1
                    }],
                actions: [{
                        type: __1.ACTION_TYPE.SET_FLAG,
                        flag: 'phase-1',
                        value: false
                    }, {
                        type: __1.ACTION_TYPE.SET_FLAG,
                        flag: 'phase-2',
                        value: true
                    }, {
                        type: __1.ACTION_TYPE.SEND_MESSAGE,
                        messageId: 'phase-2-start'
                    }, {
                        type: __1.ACTION_TYPE.TRIGGER_ZONE,
                        zone: 'red-exit',
                        value: true,
                        teams: ['red']
                    }],
                once: true
            }, {
                id: 200,
                description: "Czekamy na zaporkowanie statku",
                flagsRequired: ['phase-2'],
                triggers: [
                    {
                        type: __1.TRIGGER_TYPE.SHIP_IN_ZONE,
                        zone: 'red-exit',
                        ship: {
                            teams: ['red']
                        }
                    }
                ],
                actions: [{
                        type: __1.ACTION_TYPE.SEND_MESSAGE,
                        messageId: 'end-mission'
                    }],
                once: true
            }
        ],
        flags: [{
                id: 'phase-1',
                value: true
            }, {
                id: 'phase-2',
                value: false
            }],
        messages: [{
                id: 'game-start',
                receivers: {
                    teams: ['red']
                },
                title: 'Pierwsze rozkazy',
                text: 'Na północny zachód od was zaczną pojawiać sie konwoje wroga. Zniszczcie przynajmniej jeden statek i czekajcie na dalsze rozkazy',
                from: 'Admiralicja drużyny czerwonej!'
            }, {
                id: 'phase-2-start',
                receivers: {
                    teams: ['red']
                },
                title: 'Wracajcie do domu!',
                text: 'Wasz kapitan otrzymał koordynaty obszaru ewakuacji. Dopłyńcie tam.',
                from: 'Admiralicja drużyny czerwonej!'
            }, {
                id: 'end-mission',
                receivers: { teams: ['red'] },
                title: 'Gratulacje',
                text: 'Dobra robota!',
                from: 'Admiralicja drużyny czerwonej!'
            }],
        ai: {
            paths: [
                {
                    id: 'path-1',
                    points: [
                        [100, 100],
                        [700, 100],
                        [700, 500],
                        [-100, 500]
                    ]
                }
            ],
            spawners: [
                {
                    startTime: 1,
                    repeat: -1,
                    interval: 15,
                    ship: {
                        id: 'yellow-',
                        type: naval_map_1.NAVAL_OBJECT.SHIP,
                        position: [50, 50],
                        course: 90,
                        velosity: 5,
                        speedModel: naval_map_1.SPEED_MODEL.VELOSITY,
                        team: 'yellow',
                        name: 'BOT',
                        helm: 0,
                        torpedoStorage: []
                    },
                    path: 'path-1'
                }
            ]
        }
    };
}
exports.default = HuntingScenario;

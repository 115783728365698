import { SceneData } from "okret-engine/dist/okret-game/demos/objects";
import { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";

type Props = {

}

const Container = styled.div`
    canvas {
        width: 800px;
        height: 600px;
        border: 2px solid red;
    }
`;

function useDemo(onStateChange: (state: SceneData) => void) {
    // const { engine } = useGameClient();
    // const demo = engine.demos.objects;

    // demo.state.subscribe(state => {
    //     onStateChange(state);
    // })
}

const DemoObjects: React.FC<Props> = () => {

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const ctx = useRef<CanvasRenderingContext2D | null>(null);

    useEffect(() => {
        //@ts-ignore
        window.draw = 1;

        if (canvasRef.current) {
            const c = canvasRef.current.getContext('2d');
            if (c) {
                ctx.current = c
                c.translate(0, 600);  // Translate to the center
                c.scale(1, -1);       // Zoom in and flip y axis
            }
        }
    }, [canvasRef, ctx]);

    const onStateChange = useCallback((state: SceneData) => {
        //@ts-ignore
        if (window.draw) {
            if (ctx.current) {
                ctx.current.clearRect(0, 0, 800, 600);

                if (state) {

                    ctx.current.beginPath();
                    state.objects.forEach(o => {
                        if (ctx.current) {
                            ctx.current.moveTo(o.position[0], o.position[1]);
                            ctx.current.arc(o.position[0], o.position[1], o.radius || 1, 0, 2 * Math.PI);
                        }
                    })
                    ctx.current.stroke();

                    ctx.current.font = "30px Arial";
                    ctx.current.fillText(state.objects.length.toString(), 10, 50);
                }
            }
        }
    }, [ctx]);

    useDemo(onStateChange);

    return <Container>
        <h2>Demo: Objects</h2>
        {/* {JSON.stringify(state)} */}
        <canvas width="800" height="600" ref={canvasRef}></canvas>
    </Container>
}

export default DemoObjects;

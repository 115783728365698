import { NavalObject } from 'okret-engine';
import * as PIXI from 'pixi.js';
import { WidgetConfig } from '../create-widget';
import Echo from './echo';
import MapLines from './lines';
import NavalObjects from './naval-objects';
import Shoreline from './shoreline';
import ZonesLayer from './zones';

export default class TaskWidget extends PIXI.Application {
    public baseWidth: number;
    public baseHeight: number;
    private baseRatio: number;
    private scaleRatio = 1;

    constructor(width: number, height: number, private config: WidgetConfig) {
        super({
            width,
            height,
            transparent: true,
            antialias: true
        });

        console.log('Map init', config);

        this.baseHeight = height;
        this.baseWidth = width;
        this.baseRatio = height / width;


        window.addEventListener('resize', this.gameResize);

        this.loadAssets([]).then((resources) => {
            this.initWidget();
        })
    }

    private mapContainer = new PIXI.Container();
    private staticMap = new Shoreline();

    private navalObjects?: NavalObjects;

    private echoView?: Echo;

    private mapLines = new MapLines();

    private zonesLayer = new ZonesLayer();

    private initWidget() {

        this.mapContainer.addChild(this.mapLines);
        this.mapContainer.addChild(this.staticMap);

        if (this.config.showZones) {
            this.mapContainer.addChild(this.zonesLayer);
        }

        if (this.config.echoMode) {
            this.echoView = new Echo();
            this.mapContainer.addChild(this.echoView);
        } else {
            this.navalObjects = new NavalObjects()
            this.mapContainer.addChild(this.navalObjects);
        }

        this.stage.addChild(this.mapContainer);
    }


    private loadAssets(assets: any[]) {

        return new Promise(resolve => {
            assets.forEach(assetInfo => {
                //@ts-ignore
                this.loader.add(...assetInfo);
            })

            this.loader.load((loader, resource) => {
                resolve(null);
            })

            this.loader.onError.add((...args: any[]) => {
                console.error(...args);
            })

        })

    }

    public destroy(removeView?: boolean) {
        super.destroy(removeView);
        window.removeEventListener('resize', this.gameResize);
    }

    public gameResize = () => {
        const nW = this.config.container.offsetWidth;
        const nH = nW * this.baseRatio;

        this.renderer.resize(nW, nH);

        this.scaleRatio = (nW / this.baseWidth);

        this.stage.scale.set(this.scaleRatio)
    }

    public updateStatic(data: {
        shorelines: [number, number][][],
        zones: {
            name: string,
            shape: [number, number][]
        }[]
    }): void {
        this.staticMap.update(data.shorelines);
        this.zonesLayer.update(data.zones);
    }

    public updateDynamic(data: { navalObjects: NavalObject[] }) {
        if (this.navalObjects) {
            this.navalObjects.update(data);
        }

        if (this.echoView) {
            const center = data.navalObjects.find(no => no.id === this.config.centerOn);

            if (center) {
                this.echoView.setCenterObject(center);
                this.echoView.updateObjects(data.navalObjects);
            }
        }

        if (this.config.centerOn) {
            const center = data.navalObjects.find(no => no.id === this.config.centerOn);

            if (center) {
                this.mapContainer.pivot.set(center.position[0], center.position[1])
                this.mapContainer.position.set(this.baseWidth / 2, this.baseHeight / 2);

                if (this.config.orientWithCenter) {

                    this.mapContainer.rotation = -center.angle;
                }
            }

        }

    }

}

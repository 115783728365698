import { NavalObject } from 'okret-engine';
import { NAVAL_OBJECT } from 'okret-engine/dist/okret-game/naval-map';
import * as PIXI from 'pixi.js';
import * as SAT from 'sat';

const TIME_TO_FADE = 5;
const lineSpeed = 2 * Math.PI * (1 / TIME_TO_FADE);

export default class Echo extends PIXI.Container {
    private gfx = new PIXI.Graphics();

    private ship?: NavalObject;
    private navalObjects: NavalObject[] = [];

    private lastUpdate = Date.now();
    private lineAngle = 0;

    private visibleObjects: {
        obj: {
            id: string
            position: [number, number],
            angle: number,
            type: NAVAL_OBJECT
        },
        timeToEnd: number
    }[] = [];

    constructor() {
        super()
        this.addChild(this.gfx);

        this.loop();
    }

    private loop() {

        const now = Date.now();
        const dt = now - this.lastUpdate;
        this.lastUpdate = now;

        if (dt) {
            this.update(dt / 1000);
        }

        requestAnimationFrame(() => {
            this.loop();
        })
    }

    setCenterObject(ship: NavalObject) {
        this.ship = ship;
    }

    updateObjects(objects: NavalObject[]) {
        this.navalObjects = objects;
    }

    update(dt: number) {
        const dAngle = lineSpeed * dt;
        let oldLineAngle = this.lineAngle - dAngle;

        if (oldLineAngle < 0) {
            oldLineAngle += Math.PI * 2;
        }

        this.lineAngle += dAngle;

        if (this.lineAngle > Math.PI * 2) {
            this.lineAngle -= Math.PI * 2;
        }

        this.gfx.clear();

        if (this.ship) {
            this.gfx.lineStyle(2, 0x00ff00, 1);
            this.gfx.moveTo(this.ship.position[0], this.ship.position[1]);
            this.gfx.lineTo(this.ship.position[0] + 1000 * -Math.sin(this.lineAngle), this.ship.position[1] + 1000 * Math.cos(this.lineAngle));


            const poli = new SAT.Polygon(new SAT.Vector(this.ship.position[0], this.ship.position[1]),
                [
                    new SAT.Vector(),
                    new SAT.Vector(1000 * -Math.sin(oldLineAngle), 1000 * Math.cos(oldLineAngle)),
                    new SAT.Vector(1000 * -Math.sin(this.lineAngle), 1000 * Math.cos(this.lineAngle))
                ]);



            this.navalObjects.forEach(no => {
                if (no.id !== this.ship?.id && SAT.pointInPolygon(new SAT.Vector(no.position[0], no.position[1]), poli)) {

                    this.visibleObjects.push({
                        obj: {
                            position: [...no.position],
                            angle: no.angle,
                            type: no.type,
                            id: no.type
                        },
                        timeToEnd: TIME_TO_FADE
                    })

                }
            });

            this.visibleObjects.forEach(noData => {
                noData.timeToEnd -= dt;

                const no = noData.obj;

                if (no.type === NAVAL_OBJECT.SHIP) {
                    this.gfx.lineStyle(2, 0xff0000, noData.timeToEnd / TIME_TO_FADE);
                    this.gfx.drawCircle(no.position[0], no.position[1], 10);
                    this.gfx.moveTo(no.position[0], no.position[1]);
                    this.gfx.lineTo(no.position[0] + 10 * -Math.sin(no.angle), no.position[1] + 10 * Math.cos(no.angle))
                }

                if (no.type === NAVAL_OBJECT.TORPEDO) {
                    this.gfx.lineStyle(1, 0xff0000, noData.timeToEnd / TIME_TO_FADE);
                    this.gfx.drawCircle(no.position[0], no.position[1], 3);
                    this.gfx.moveTo(no.position[0], no.position[1]);
                    this.gfx.lineTo(no.position[0] + 3 * -Math.sin(no.angle), no.position[1] + 3 * Math.cos(no.angle))
                }
            });

            this.visibleObjects = this.visibleObjects.filter(({ timeToEnd }) => {
                return timeToEnd > 0;
            })

        }

    }
}

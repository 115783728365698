import RoomModel from "gde-game-framework/src/models/room";
import React from "react";
import NavalMap from "../../../components/map";
import ScenarioComs from "../../../components/scenario-coms";
import useShip from "../../../modules/game/hooks/engine/use-ship";

type Props = {
    room: RoomModel,
    shipID: string
}

const CaptainRoom: React.FC<Props> = ({ room, shipID }) => {

    const { ship } = useShip(shipID);

    return <div>
        <h2>Kajuta kapitana</h2>
        <p>Statek: {ship?.name}</p>

        <p>Aktualna prędkość: {ship?.velosity}</p>

        <div>
            <h3>Mapa</h3>
            <div style={{
                maxWidth: '100vh'
            }}>
                <NavalMap showZones={true} />
            </div>
        </div>

        <div>
            <h3>Komunikacja</h3>
            <ScenarioComs shipID={shipID} />
            <h3>Chat</h3>
            <ul>
                Komunikacja z innymi statkami
            </ul>
        </div>
    </div>
};

export default CaptainRoom;

import React, { useEffect } from "react";
import UsersInRoom from "../../../components/users-in-room";
import useRoom from "../../../modules/game/hooks/rooms/use-room";
import { useRooms } from "../../../modules/game/hooks/rooms/use-rooms";
import useRoomsList from "../../../modules/game/hooks/rooms/use-rooms-list";

type Props = {
    roomId: string
}

const TeamLobby: React.FC<Props> = ({roomId}) => {

    const {room} = useRoom(roomId);
    const { rooms } = useRoomsList();
    const { enter } = useRooms();

    useEffect(()=> {
        console.log(rooms);
    }, [rooms])

    return <div>
        <h2>{room?.name}</h2>
        <h3>Gracze:</h3>
        <UsersInRoom id={roomId} />
        <h3>Wybierz stanowisko</h3>
        {rooms?.filter(r => r.tags.includes('ship-room'))
                .map(room => {
                    return <ul key={room.id}>
                        <li><button onClick={() => enter(room.id)}>{room.name}</button></li>
                    </ul>
                })}
    </div>
}

export default TeamLobby;

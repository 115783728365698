import CONFIG from "../config";
import createClient from "./create-client";


const WS_URL = CONFIG.GAME_SERVER_URL

const {
    GameClientProvider,
    useGameFramework
} = createClient(WS_URL);


export {
    GameClientProvider,
    useGameFramework
}
